import React from 'react'

import * as styles from './Releases.module.scss'

import classnames from 'classnames'
import Markdown from 'src/components/UI/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'
import { PlayIcon } from 'src/components/UI/Icon/Icon'
import SbEditable from 'storyblok-react'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

const ReleaseItem = ({ blok }: any) => {
  const { artwork, title, label } = blok
  return (
    <div className={styles.release}>
      <div className={styles.artworkWrapper}>
        {artwork && artwork.filename && (
          <div className={styles.artwork}>
            <Image src={artwork.filename} alt={artwork.title} />
          </div>
        )}
      </div>
      <div className={styles.releaseInfo}>
        <div className={styles.releaseTop}>
          {title && <h3 className={styles.releaseTitle}>{title}</h3>}
          {label && <div className={styles.releaseLabel}>{label}</div>}
        </div>
        <div className={styles.releaseBottom}>
          <div className={styles.releaseButton}>
            <span className={styles.releaseButtonText}>Spela</span>
            <PlayIcon className={styles.releaseIcon} width={40} height={40} />
          </div>
        </div>
      </div>
    </div>
  )
}
const Releases = ({ blok }: any) => {
  const { title, text, items } = blok

  return (
    <SbEditable content={blok}>
      <section className={styles.wrapper}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {text && <Markdown className={styles.text}>{text}</Markdown>}
        <div className={styles.releases}>
          {items &&
            items.length > 0 &&
            items.map((blok: any, index: number) => {
              const { _uid, link } = blok
              return (
                <div key={_uid} className={styles.releaseWrapper}>
                  {link ? (
                    <LinkComponent to={link}>
                      <ReleaseItem blok={blok} />
                    </LinkComponent>
                  ) : (
                    <ReleaseItem blok={blok} />
                  )}
                </div>
              )
            })}
        </div>
      </section>
    </SbEditable>
  )
}

export default Releases
