import * as React from 'react'

import * as styles from './Checkbox.module.scss'

type Props = {
  name: string
  children: any
  onChange: any
  error: boolean
  required?: boolean
}

const Checkbox = ({ name, onChange, error, children, required }: Props) => {
  const [checked, setChecked] = React.useState(false)
  const handleOnChange = (e: any) => {
    setChecked(e.target.checked)
    if (onChange) {
      onChange(e.target.checked)
    }
  }
  return (
    <div
      className={
        error ? [styles.wrapper, styles.error].join(' ') : styles.wrapper
      }
    >
      <input
        onChange={handleOnChange}
        type="checkbox"
        name={name}
        id={name}
        required={required}
        className={styles.checkbox}
      />
      <label htmlFor={name}>
        <span>{children}</span>
      </label>
      {checked && (
        <div className={styles.checkmark}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L7 7" stroke="var(--blue)" strokeWidth="2" />
            <path d="M1 7L7 1" stroke="var(--blue)" strokeWidth="2" />
          </svg>
        </div>
      )}
    </div>
  )
}

export default Checkbox
