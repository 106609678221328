import React, { useRef, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import * as styles from './PageSingleLaunch.module.scss'
import Components from 'src/storyblok/Components'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import Markdown from 'src/components/UI/Markdown/Markdown'
import SEOPage from 'src/components/SEOPage/SEOPage'
import StoryblokImage from 'src/storyblok/Components/StoryblokImage/StoryblokImage'
import Image from 'src/components/UI/Image/Image'
import Button from 'src/components/UI/Button/Button'
import { motion, useScroll, useTransform } from 'framer-motion'

const PageSingleLaunch = ({ blok, full_slug, ...rest }: any) => {
  const {
    seo_data,
    hero_button_link,
    body,
    footer_links,
    copyright_text,
    logos,
    image_hakan,
    image_frog,
    image_flower_1,
    image_flower_2,
    image_flower_3,
    image_title,
    image_logo_outer,
    image_logo_inner,
  } = blok

  let sectionRef = useRef(null)
  const { scrollYProgress } = useScroll()
  const animateTitleOpacity = useTransform(scrollYProgress, [0, 0.2], [1, 0])
  const animateGraphicsOpacity = useTransform(scrollYProgress, [0, 0.8], [1, 0])

  const animateHakanScale = useTransform(scrollYProgress, [0, 0.8], [0.9, 1])
  const animateHakanY = useTransform(scrollYProgress, [0, 0.8], ['20%', 0])

  const animateFlower1Rotation = useTransform(
    scrollYProgress,
    [0, 0.8],
    [0, -35]
  )
  const animateFrogRotation = useTransform(scrollYProgress, [0, 0.9], [0, 35])
  const animateFlower2Rotation = useTransform(
    scrollYProgress,
    [0, 0.65],
    [0, 15]
  )
  const animateFlower3Rotation = useTransform(
    scrollYProgress,
    [0, 0.7],
    [0, 180]
  )

  return (
    <SbEditable content={blok}>
      <SEOPage path={full_slug} storyblokData={seo_data} />

      <div className={styles.wrapper}>
        <section className={styles.hero}>
          <div className={styles.logo}>
            {image_logo_outer && (
              <div className={[styles.logoOuter, styles.logoWrapper].join(' ')}>
                <Image
                  src={image_logo_outer.filename}
                  alt={image_logo_outer.title}
                />
              </div>
            )}
            {image_logo_inner && (
              <div className={[styles.logoInner, styles.logoWrapper].join(' ')}>
                <Image
                  src={image_logo_inner.filename}
                  alt={image_logo_inner.title}
                />
              </div>
            )}
          </div>
          {image_title && (
            <motion.div
              className={styles.title}
              style={{ opacity: animateTitleOpacity }}
            >
              <Image
                src={image_title.filename}
                alt={image_title.title}
                className={[].join(' ')}
              />
            </motion.div>
          )}
          <motion.div
            className={styles.heroImageWrapper}
            style={{ opacity: animateGraphicsOpacity }}
          >
            {image_hakan && (
              <motion.div
                style={{
                  scale: animateHakanScale,
                  y: animateHakanY,
                  x: '-50%',
                }}
                className={[styles.heroImage, styles.hakan].join(' ')}
              >
                <Image src={image_hakan.filename} alt={image_hakan.title} />
              </motion.div>
            )}
            {image_frog && (
              <div className={[styles.heroImage, styles.frog].join(' ')}>
                <motion.div style={{ rotate: animateFrogRotation }}>
                  <Image src={image_frog.filename} alt={image_frog.title} />
                </motion.div>
              </div>
            )}
            {image_flower_1 && (
              <motion.div
                style={{ rotate: animateFlower1Rotation }}
                className={[styles.heroImage, styles.flower1].join(' ')}
              >
                <Image
                  src={image_flower_1.filename}
                  alt={image_flower_1.title}
                />
              </motion.div>
            )}
            {image_flower_2 && (
              <motion.div
                style={{ rotate: animateFlower2Rotation }}
                className={[styles.heroImage, styles.flower2].join(' ')}
              >
                <Image
                  src={image_flower_2.filename}
                  alt={image_flower_2.title}
                />
              </motion.div>
            )}
            {image_flower_3 && (
              <motion.div
                style={{ rotate: animateFlower3Rotation }}
                className={[styles.heroImage, styles.flower3].join(' ')}
              >
                <Image
                  src={image_flower_3.filename}
                  alt={image_flower_3.title}
                />
              </motion.div>
            )}
          </motion.div>
          <div className={styles.cta}>
            {hero_button_link && (
              <Button href={hero_button_link.cached_url}>
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_17_333)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M22.281 12.411C17.7683 9.73117 10.325 9.485 6.0165 10.7928C5.32467 11.0028 4.59317 10.612 4.38433 9.92017C4.17433 9.22833 4.564 8.49683 5.257 8.28683C10.2025 6.78533 18.4228 7.07583 23.618 10.1593C24.2398 10.5292 24.444 11.3318 24.0753 11.9537C23.7067 12.5755 22.9028 12.7808 22.281 12.411ZM22.134 16.3812C21.8167 16.8945 21.1458 17.0555 20.6325 16.7405C16.87 14.4282 11.1335 13.7573 6.6815 15.1083C6.10517 15.2833 5.495 14.9578 5.32 14.3815C5.14617 13.804 5.47167 13.1962 6.048 13.02C11.1323 11.4777 17.4533 12.2243 21.7747 14.8808C22.288 15.1958 22.449 15.869 22.134 16.3812ZM20.4202 20.1927C20.1693 20.6057 19.6315 20.7352 19.2208 20.4832C15.9332 18.4742 11.795 18.0203 6.92183 19.1333C6.45283 19.2407 5.985 18.9467 5.87767 18.4777C5.77033 18.0075 6.06317 17.5397 6.53333 17.4323C11.8662 16.2132 16.4407 16.7382 20.1308 18.9933C20.5427 19.2442 20.6722 19.782 20.4202 20.1927ZM14 0C6.2685 0 0 6.2685 0 14C0 21.7327 6.2685 28 14 28C21.7327 28 28 21.7327 28 14C28 6.2685 21.7327 0 14 0Z"
                      fill="#DBD7CD"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_17_333">
                      <rect width="28" height="28" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Lyssna Nu
              </Button>
            )}
          </div>
          <div className={styles.gradient} />
        </section>

        <div className={styles.content}>
          {body &&
            body.length > 0 &&
            body.map((blok: any) => {
              return React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })
            })}
        </div>

        <footer className={styles.footer}>
          {footer_links && footer_links?.length > 0 && (
            <div className={styles.footerLinks}>
              {footer_links.map((link: any, i: number) => {
                const { _uid, title, url } = link
                return url?.cached_url ? (
                  <React.Fragment key={_uid}>
                    <LinkComponent to={url.cached_url} title={title}>
                      {title}
                    </LinkComponent>
                    {i < footer_links.length - 1 ? ` • ` : ``}
                  </React.Fragment>
                ) : null
              })}
            </div>
          )}

          {copyright_text && (
            <p className={styles.copyrightText}>
              <Markdown>{copyright_text}</Markdown>
            </p>
          )}
          {logos &&
            logos.map((blok: any) => (
              <a
                href="https://www.instagram.com/trotvivelrecs/"
                key={blok._uid}
                target="_blank"
              >
                <StoryblokImage blok={blok} />
              </a>
            ))}
        </footer>
      </div>
    </SbEditable>
  )
}

export default PageSingleLaunch
