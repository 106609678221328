import React from 'react'

import * as styles from './Merchandise.module.scss'

import classnames from 'classnames'
import Markdown from 'src/components/UI/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'
import { PlayIcon } from 'src/components/UI/Icon/Icon'
import SbEditable from 'storyblok-react'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

const Product = ({ blok }: any) => {
  const { image, title } = blok
  return (
    <div className={styles.product}>
      <div className={styles.imageWrapper}>
        {image && image.filename && (
          <div className={styles.image}>
            <Image src={image.filename} alt={image.title} />
          </div>
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.top}>
          {title && <h3 className={styles.title}>{title}</h3>}
        </div>
      </div>
    </div>
  )
}
const Merchandise = ({ blok }: any) => {
  const { title, text, items, button } = blok

  return (
    <SbEditable content={blok}>
      <section className={styles.wrapper}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {text && <Markdown className={styles.text}>{text}</Markdown>}
        <div className={styles.products}>
          {items &&
            items.length > 0 &&
            items.map((blok: any, index: number) => {
              const { _uid, url } = blok
              const { cached_url } = url

              return (
                <div key={_uid} className={styles.productItem}>
                  {cached_url ? (
                    <LinkComponent to={cached_url}>
                      <Product blok={blok} />
                    </LinkComponent>
                  ) : (
                    <Product blok={blok} />
                  )}
                </div>
              )
            })}
        </div>
        {button && button?.length > 0 && (
          <div className={styles.cta}>
            {button.map((blok: any) => {
              const { title, url } = blok
              const { cached_url } = url

              return cached_url ? (
                <LinkComponent
                  to={cached_url}
                  className={styles.button}
                  title={title}
                >
                  {title}
                </LinkComponent>
              ) : null
            })}
          </div>
        )}
      </section>
    </SbEditable>
  )
}

export default Merchandise
