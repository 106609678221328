// extracted by mini-css-extract-plugin
export var button = "Merchandise-module--button--+LAnL";
export var cta = "Merchandise-module--cta--4Q74N";
export var image = "Merchandise-module--image--lhAys";
export var imageWrapper = "Merchandise-module--imageWrapper--MrO9B";
export var info = "Merchandise-module--info--TNE2T";
export var product = "Merchandise-module--product--Mtyzv";
export var productItem = "Merchandise-module--productItem--ccZ+T";
export var products = "Merchandise-module--products--1s+50";
export var text = "Merchandise-module--text--a-boP";
export var title = "Merchandise-module--title--n3vhz";
export var wrapper = "Merchandise-module--wrapper--4VoZb";