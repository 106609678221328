import React from 'react'
import * as styles from './Newsletter.module.scss'
import NewsletterForm from 'src/components/NewsletterForm/NewsletterForm'
import SbEditable from 'storyblok-react'

const Newsletter = ({ blok }: any) => {
  const { title, description, terms_text } = blok
  return (
    <SbEditable content={blok}>
      <section className={styles.wrapper}>
        <div className={styles.info}>
          <div>
            {title && <h2 className="heading">{title}</h2>}
            {description && <p className="preamble">{description}</p>}
          </div>
        </div>
        <div className={styles.newsletter}>
          <NewsletterForm text={terms_text} />
        </div>
      </section>
    </SbEditable>
  )
}

export default Newsletter
