// extracted by mini-css-extract-plugin
export var border = "PageCampaign-module--border--RGjxT";
export var borderDesktop = "PageCampaign-module--borderDesktop--QY3rP";
export var borderMobile = "PageCampaign-module--borderMobile--8ArbH";
export var content = "PageCampaign-module--content--S82rT";
export var contentWrapper = "PageCampaign-module--contentWrapper--1uXC0";
export var copyrightText = "PageCampaign-module--copyrightText--B+puh";
export var fadeIn = "PageCampaign-module--fadeIn--9Uk8w";
export var footer = "PageCampaign-module--footer--Vuu-4";
export var footerLinks = "PageCampaign-module--footerLinks--VVIgq";
export var header = "PageCampaign-module--header--NxU0W";
export var headerTitle = "PageCampaign-module--headerTitle--Apo+E";
export var hero = "PageCampaign-module--hero--LWawf";
export var icon = "PageCampaign-module--icon--dIhJy";
export var iconPlaceholder = "PageCampaign-module--iconPlaceholder--v43L8";
export var liveBig = "PageCampaign-module--liveBig--BjCOc";
export var smallIcon = "PageCampaign-module--smallIcon--F7l0-";
export var starsBottom = "PageCampaign-module--starsBottom--49h0b";
export var starsTop = "PageCampaign-module--starsTop--QYiHd";
export var topTitle = "PageCampaign-module--topTitle--jkdEg";
export var wrapper = "PageCampaign-module--wrapper--MrHRw";
export var yearBig = "PageCampaign-module--yearBig--UrhRV";