import React, { useState } from 'react'
import Checkbox from '../UI/Checkbox/Checkbox'
import * as styles from './NewsletterForm.module.scss'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

const url =
  'https://hakanhellstrom.us14.list-manage.com/subscribe/post?u=fca601d027569e1cbf721afae&amp;id=69bf70314d'

const NewsletterForm = ({ text }: { text: string }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const checkOnChange = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 2000)
    }, 2000)
  }

  const onChange = (e: any) => {
    setEmail(e.target.value)
  }
  return (
    <div className={styles.wrapper}>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }: any) => {
          console.log(status, message)
          return (
            <form
              onSubmit={(e: any) => {
                e.preventDefault()
                subscribe({ EMAIL: email })
              }}
            >
              <div className={styles.top}>
                <div>
                  <input
                    className={styles.input}
                    type="email"
                    name="mail"
                    onChange={onChange}
                    required
                    placeholder="Din e-mail"
                  />
                  <Checkbox
                    error={false}
                    required
                    onChange={checkOnChange}
                    name="terms"
                  >
                    <span className="body-text">{text}</span>
                  </Checkbox>
                </div>
              </div>
              <div className={styles.bottom}>
                <div className={styles.bg}></div>
                <div>
                  <button disabled={status !== null} className="button">
                    <span>
                      {status === null
                        ? 'Prenumerera'
                        : status === 'sending'
                        ? 'Laddar'
                        : 'Tack'}
                    </span>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="30"
                        viewBox="0 0 40 30"
                        fill="none"
                      >
                        <path
                          d="M24.825 0.0999756L39.075 14.35V15.65L24.825 29.9L21.925 27L32.025 17H0.924988V13.05H32.075L21.925 2.99998L24.825 0.0999756Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </form>
          )
        }}
      />
      {/* <form onSubmit={onSubmit}>
        <input
          className={styles.input}
          type="email"
          name="mail"
          required
          placeholder="Din e-mail"
        />
        <input
          type="submit"
          className={styles.button}
          value={'Signa upp mig'}
        />

        <Checkbox error={false} required onChange={checkOnChange} name="terms">
          <span>
            Jag godkänner härmed att min emailadress behandlas av FKP Scorpio
            Sverige för att jag ska kunna ta emot nyhetsbrev och information av
            dem. Jag bekräftar att jag är över 16 år, och har i annat fall mina
            föräldrars godkännande att prenumerera på nyhetsbrevet{' '}
            <a href="https://www.fkpscorpio.se/datapolicy/" target="_blank">
              datapolicy
            </a>
          </span>
        </Checkbox>
      </form> */}
    </div>
  )
}

export default NewsletterForm
