const format = (image: string, resize: string, filter: string) => {
  // console.log("image",image + `/m/${resize}`);
  
  // const imageService = 'https://img2.storyblok.com/'
  // const path = image.replace('https://a.storyblok.com', '')
  return image + `/m/${resize}${filter}`
}

// Check if webp is accepted
export const formatImage = (
  src: string,
  size: number,
  ratio: number,
  strictWidth?: string
) => {
  let filters = 'filters:quality(75)'


  if (src && src !== '') {
    if (size) {
      const width = size

      const height = ratio ? Math.round(width / ratio) : 0

      return format(src, `${width}x${height}/`, filters)
    } else if (strictWidth) {
      return format(src, `${strictWidth}x/`, filters)
    } else {
      return format(src, '', filters)
    }
  }
  return ''
}
