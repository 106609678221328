// extracted by mini-css-extract-plugin
export var artwork = "Releases-module--artwork--WFyQL";
export var artworkWrapper = "Releases-module--artworkWrapper--vbZRk";
export var release = "Releases-module--release--LDG8v";
export var releaseBottom = "Releases-module--releaseBottom--4I9Uv";
export var releaseButton = "Releases-module--releaseButton--dF2QG";
export var releaseButtonText = "Releases-module--releaseButtonText--6zWDq";
export var releaseIcon = "Releases-module--releaseIcon--oAEgU";
export var releaseInfo = "Releases-module--releaseInfo--6Rbid";
export var releaseLabel = "Releases-module--releaseLabel--y+QrE";
export var releaseTitle = "Releases-module--releaseTitle--sMwrv";
export var releaseWrapper = "Releases-module--releaseWrapper--qgzYb";
export var releases = "Releases-module--releases--aDP+U";
export var text = "Releases-module--text--f0xMn";
export var wrapper = "Releases-module--wrapper--CCr-s";