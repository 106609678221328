// extracted by mini-css-extract-plugin
export var background = "PageTour-module--background--80iDx";
export var bottom = "PageTour-module--bottom--DLheB";
export var content = "PageTour-module--content--vF3zK";
export var countdown = "PageTour-module--countdown--Ulb1w";
export var footer = "PageTour-module--footer--KaBmX";
export var hero = "PageTour-module--hero--0ljtF";
export var heroImage = "PageTour-module--heroImage--4afAT";
export var item = "PageTour-module--item--iw3zl";
export var link = "PageTour-module--link--MstDn";
export var links = "PageTour-module--links--D+t4e";
export var logo = "PageTour-module--logo--jZWs6";
export var top = "PageTour-module--top--D+Vhu";
export var wrapper = "PageTour-module--wrapper--qIFOl";