import React from 'react'

import SbEditable from 'storyblok-react'
import * as styles from './Footer.module.scss'
import { Props } from './types'

const Footer = ({ blok }: Props): JSX.Element => {
  return (
    <SbEditable content={blok}>
      <footer>Footer</footer>
    </SbEditable>
  )
}

export default Footer
