import React from 'react'
import Question from './Question/Question'
import * as styles from './FAQ.module.scss'

import classnames from 'classnames'

import SbEditable from 'storyblok-react'
const FAQ = ({ blok }: any) => {
  const { title, description, items } = blok
  return (
    <SbEditable content={blok}>
      <section className={classnames(styles.wrapper)}>
        <div className={styles.info}>
          {title && <h2 className="heading">{title}</h2>}
          {description && <p className="preamble">{description}</p>}
        </div>
        <div className={styles.questions}>
          {items &&
            items.length > 0 &&
            items.map((question: any, index: number) => (
              <Question
                key={`question-${index}`}
                question={question}
                className={styles.question}
              />
            ))}
        </div>
      </section>
    </SbEditable>
  )
}

export default FAQ
