import React from 'react'
import * as styles from './Question.module.scss'
import Markdown from 'src/components/UI/Markdown/Markdown'
import classnames from 'classnames'
const Question = ({ question, className }: any) => {
  const [active, setActive] = React.useState(false)

  return (
    <div
      className={[className, styles.wrapper, active ? styles.active : ''].join(
        ' '
      )}
    >
      <button
        onClick={() => {
          setActive(!active)
        }}
        className={classnames(styles.question, 'use-editor')}
      >
        {question?.question && question.question}
      </button>
      <div className={styles.answer}>
        {question?.answer && (
          <div>
            <Markdown>{question.answer}</Markdown>
          </div>
        )}
      </div>
    </div>
  )
}

export default Question
