import React from 'react'
import SbEditable from 'storyblok-react'
import * as styles from './PageHome.module.scss'
import Components from 'src/storyblok/Components'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import Markdown from 'src/components/UI/Markdown/Markdown'
import SEOPage from 'src/components/SEOPage/SEOPage'
import StoryblokImage from 'src/storyblok/Components/StoryblokImage/StoryblokImage'
import {
  LiveBigIcon,
  LiveIcon,
  YearBigIcon,
  YearIcon,
} from 'src/components/UI/Icon/Icon'

const PageHome = ({ blok, full_slug, ...rest }: any) => {
  const {
    seo_data,
    mobile_image,
    top_title,
    body,
    footer_links,
    copyright_text,
    logos,
  } = blok

  return (
    <SbEditable content={blok}>
      <SEOPage path={full_slug} storyblokData={seo_data} />
      <div className={styles.starsTop}>
        <svg width="100%" height="auto" fill="none" viewBox="0 0 1488 211">
          <path
            d="m116.978 31.52 6.409 2.812 5.964-3.646-.71 6.896 5.327 4.495-6.837 1.45-2.664 6.413-3.522-5.99-6.986-.526 4.677-5.168-1.658-6.736ZM3.762 45.445l6.393 2.796 5.964-3.63-.71 6.895L20.751 56l-6.852 1.45-2.664 6.413-3.522-5.99-6.985-.526 4.676-5.168-1.642-6.735Zm15.613 151.437 3.167 3.382 4.618-.644-2.294 4.012 2.057 4.143-4.573-.908-3.345 3.222-.547-4.583-4.115-2.153 4.233-1.932.799-4.539Zm275.376-51.158 3.167 3.397 4.603-.659-2.279 4.027 2.057 4.143-4.573-.907-3.345 3.221-.547-4.583-4.115-2.152 4.233-1.933.799-4.554ZM1484.25 45.444l-6.41 2.797-5.96-3.63.71 6.895-5.33 4.495 6.84 1.45 2.66 6.413 3.53-5.99 6.98-.526-4.67-5.168 1.65-6.735ZM1391.28.89l-3.17 3.383-4.61-.644 2.29 4.01-2.06 4.145 4.58-.893 3.34 3.206.55-4.583 4.11-2.152-4.23-1.933-.8-4.54Zm77.34 195.992-3.15 3.382-4.62-.644 2.28 4.012-2.05 4.143 4.57-.908 3.34 3.222.55-4.583 4.13-2.153-4.25-1.932-.8-4.539ZM1067.79 46.63l-3.16 3.383-4.62-.644 2.29 4.026-2.05 4.129 4.57-.893 3.34 3.206.54-4.582 4.12-2.153-4.23-1.932-.8-4.54ZM857.92 59.837l-3.167 3.397-4.618-.66 2.279 4.028-2.057 4.143 4.573-.907 3.36 3.206.533-4.583 4.129-2.152-4.233-1.918-.799-4.554Z"
            fill="#DD709A"
          />
        </svg>
      </div>
      <div className={styles.starsBottom}>
        <svg width="100%" fill="none" viewBox="0 0 1488 210">
          <path
            d="m116.978 178.569 6.409-2.812 5.964 3.646-.71-6.896 5.327-4.495-6.837-1.45-2.664-6.413-3.522 5.989-6.986.527 4.677 5.168-1.658 6.736ZM3.762 164.644l6.393-2.796 5.964 3.63-.71-6.895 5.342-4.495-6.852-1.45-2.664-6.413-3.522 5.989-6.985.527 4.676 5.168-1.642 6.735ZM19.375 13.207l3.167-3.382 4.618.644-2.294-4.012 2.057-4.143-4.573.908L19.005 0l-.547 4.583-4.115 2.153 4.233 1.932.799 4.539Zm275.376 51.158 3.167-3.397 4.603.659-2.279-4.027 2.057-4.143-4.573.907-3.345-3.221-.547 4.583-4.115 2.152 4.233 1.933.799 4.554ZM1484.25 164.644l-6.41-2.796-5.96 3.63.71-6.895-5.33-4.495 6.84-1.45 2.66-6.413 3.53 5.989 6.98.527-4.67 5.168 1.65 6.735Zm-92.97 44.555-3.17-3.383-4.61.644 2.29-4.011-2.06-4.144 4.58.893 3.34-3.206.55 4.583 4.11 2.152-4.23 1.933-.8 4.539Zm77.34-195.992-3.15-3.382-4.62.644 2.28-4.012-2.05-4.143 4.57.908L1468.99 0l.55 4.583 4.13 2.153-4.25 1.932-.8 4.539Zm-400.83 150.252-3.16-3.383-4.62.644 2.29-4.026-2.05-4.129 4.57.893 3.34-3.206.54 4.582 4.12 2.153-4.23 1.932-.8 4.54Zm-209.87-13.207-3.167-3.397-4.618.659 2.279-4.027-2.057-4.143 4.573.907 3.36-3.206.533 4.583 4.129 2.152-4.233 1.918-.799 4.554Z"
            fill="#DD709A"
          />
        </svg>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.yearBig}>
          <YearBigIcon />
        </div>
        <div className={styles.liveBig}>
          <LiveBigIcon />
        </div>
        <div className={styles.topAnim}>
          <div className={styles.header}>
            <div className={styles.icon}>
              <LiveIcon className={styles.smallIcon} />
            </div>
            <div
              className={styles.iconPlaceholder}
              style={{ width: '416px', flexShrink: '0' }}
            />
            <div className={styles.icon}>
              <YearIcon className={styles.smallIcon} />
            </div>

            <div className={styles.border}>
              <svg
                className={styles.borderMobile}
                width="306"
                height="33"
                viewBox="0 0 306 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 31.3887H41.0787C50.309 31.3887 57.8206 17.6215 57.8206 10.2923V10.1871H82.5132C84.9363 10.1871 87.1965 9.23708 88.5557 7.64378L92.7583 2.71957C93.6783 1.64333 95.204 1 96.8434 1L208.82 1C210.656 1 212.352 1.73051 213.352 2.95405L217.115 7.55059C218.467 9.20101 220.753 10.1871 223.233 10.1871H248.176V10.4215C248.176 17.6786 255.611 31.3887 264.755 31.3887H305.992"
                  stroke="#F7CB5C"
                  strokeWidth="2"
                />
              </svg>
              <svg
                width="416"
                className={styles.borderDesktop}
                height="34"
                fill="none"
              >
                <path
                  d="M0 32.389h41.079c9.23 0 16.742-13.768 16.742-21.097v-.105h24.692c2.423 0 4.683-.95 6.043-2.543l4.202-4.924C93.678 2.643 95.204 2 96.843 2H318.82c1.836 0 3.532.73 4.532 1.954l3.763 4.597c1.352 1.65 3.638 2.636 6.118 2.636h24.943v.235c0 7.257 7.435 20.967 16.579 20.967h41.237"
                  stroke="#F7CB5C"
                  strokeWidth="3"
                />
              </svg>

              <span className={styles.headerTitle}>
                FKP Scorpio Presenterar
              </span>
            </div>
          </div>
          <div className={styles.hero}>
            {mobile_image &&
              mobile_image.map((blok: any) => (
                <StoryblokImage blok={blok} key={blok._uid} />
              ))}

            {top_title && (
              <Markdown className={styles.topTitle}>{top_title}</Markdown>
            )}
          </div>
        </div>

        <div className={styles.content}>
          {body &&
            body.length > 0 &&
            body.map((blok: any) => {
              return React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })
            })}
        </div>

        <footer className={styles.footer}>
          {footer_links && footer_links?.length > 0 && (
            <div className={styles.footerLinks}>
              {footer_links.map((link: any, i: number) => {
                const { _uid, title, url } = link
                return url?.cached_url ? (
                  <React.Fragment>
                    <LinkComponent to={url.cached_url} title={title} key={_uid}>
                      {title}
                    </LinkComponent>
                    {i < footer_links.length - 1 ? ` • ` : ``}
                  </React.Fragment>
                ) : null
              })}
            </div>
          )}

          {copyright_text && (
            <p className={styles.copyrightText}>
              <Markdown>{copyright_text}</Markdown>
            </p>
          )}
          {logos &&
            logos.map((blok: any) => (
              <StoryblokImage blok={blok} key={blok._uid} />
            ))}
        </footer>
      </div>
    </SbEditable>
  )
}

export default PageHome
