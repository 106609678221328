// extracted by mini-css-extract-plugin
export var content = "PageSingleLaunch-module--content--kDR4D";
export var copyrightText = "PageSingleLaunch-module--copyrightText--nL4J8";
export var cta = "PageSingleLaunch-module--cta--BjYKY";
export var flower1 = "PageSingleLaunch-module--flower1--UMn3y";
export var flower2 = "PageSingleLaunch-module--flower2--SEF8K";
export var flower3 = "PageSingleLaunch-module--flower3--PwoSz";
export var footer = "PageSingleLaunch-module--footer--VTLo0";
export var footerLinks = "PageSingleLaunch-module--footerLinks--WtGiS";
export var frog = "PageSingleLaunch-module--frog--cZo+-";
export var gradient = "PageSingleLaunch-module--gradient--ZfghG";
export var hakan = "PageSingleLaunch-module--hakan--wUcH-";
export var hero = "PageSingleLaunch-module--hero--HAjVP";
export var heroImage = "PageSingleLaunch-module--heroImage--jX8sf";
export var heroImageWrapper = "PageSingleLaunch-module--heroImageWrapper--eJXeb";
export var logo = "PageSingleLaunch-module--logo--kbmcd";
export var logoInner = "PageSingleLaunch-module--logoInner--L7MMc";
export var logoOuter = "PageSingleLaunch-module--logoOuter--mlBwX";
export var logoWrapper = "PageSingleLaunch-module--logoWrapper--i2rgl";
export var rotate = "PageSingleLaunch-module--rotate--w+XFU";
export var title = "PageSingleLaunch-module--title--zIlLl";
export var wrapper = "PageSingleLaunch-module--wrapper--QK1SX";