import React from 'react'
import * as styles from './Tour.module.scss'

import SbEditable from 'storyblok-react'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import classnames from 'classnames'
const Tour = ({ blok }: any) => {
  const { title, dates, button } = blok
  return (
    <SbEditable content={blok}>
      <section className={styles.wrapper}>
        {title && <h2>{title}</h2>}
        <div className={styles.dates}>
          {dates &&
            dates?.length > 0 &&
            dates.map((date: any) => {
              const { title, url, status, _uid } = date
              let label = ''
              switch (status) {
                case 'sold_out':
                  label = 'Utsålt'
                  break
                case 'few':
                  label = 'Fåtal'
                  break
                case 'extra':
                  label = 'Extra'
                  break
                default:
                  label = ''
              }

              const isSoldOut = status === 'sold_out'
              return (
                <div
                  key={_uid}
                  className={classnames(
                    styles.date,
                    isSoldOut ? styles.soldOut : ''
                  )}
                >
                  {url?.cached_url && !isSoldOut ? (
                    <LinkComponent to={url.cached_url} title={title}>
                      <p>
                        {title && <span className={styles.title}>{title}</span>}
                        {label && <span className={styles.label}>{label}</span>}
                      </p>
                    </LinkComponent>
                  ) : (
                    <p>
                      {title && <span className={styles.title}>{title}</span>}
                      {label && <span className={styles.label}>{label}</span>}
                    </p>
                  )}
                </div>
              )
            })}
        </div>
        {button && button?.length > 0 && (
          <div className={styles.cta}>
            {button.map((blok: any) => {
              const { title, url } = blok
              const { cached_url } = url
              return cached_url ? (
                <LinkComponent
                  to={cached_url}
                  className={styles.button}
                  title={title}
                >
                  {title}
                </LinkComponent>
              ) : null
            })}
          </div>
        )}
      </section>
    </SbEditable>
  )
}

export default Tour
