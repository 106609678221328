// extracted by mini-css-extract-plugin
export var border = "PageHome-module--border--RISgF";
export var borderDesktop = "PageHome-module--borderDesktop--5BneC";
export var borderMobile = "PageHome-module--borderMobile--UXAr5";
export var content = "PageHome-module--content--KVC9h";
export var copyrightText = "PageHome-module--copyrightText--Woz+A";
export var fadeIn = "PageHome-module--fadeIn--MgGqI";
export var footer = "PageHome-module--footer--t3S4K";
export var footerLinks = "PageHome-module--footerLinks--3+3kV";
export var header = "PageHome-module--header--Om7KY";
export var headerTitle = "PageHome-module--headerTitle--BZI1x";
export var hero = "PageHome-module--hero--A9QGo";
export var icon = "PageHome-module--icon--40PpU";
export var iconPlaceholder = "PageHome-module--iconPlaceholder--w9Yct";
export var liveBig = "PageHome-module--liveBig--n9g+F";
export var smallIcon = "PageHome-module--smallIcon--mmePz";
export var starsBottom = "PageHome-module--starsBottom--7lH0L";
export var starsTop = "PageHome-module--starsTop--Og8nD";
export var topTitle = "PageHome-module--topTitle--fjcCs";
export var wrapper = "PageHome-module--wrapper--L8eth";
export var yearBig = "PageHome-module--yearBig--ewCs2";